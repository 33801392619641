$ENV: production;
/**
 * @license
 * MyFonts Webfont Build ID 3439917, 2017-08-21T07:36:11-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: NeueHaasUnicaPro-Bold by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/bold/
 * Copyright: Copyright &#x00A9; 2015 Monotype Imaging Inc.  All rights reserved.
 * Licensed pageviews: 250,000
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3439917
 *
 * © 2017 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
// @import url("//hello.myfonts.net/count/347d2d");

@font-face {
  font-family: 'NeueHaas-Bold';
  font-weight: bold;
  src: url('../fonts/347D2D_0_0.woff2') format('woff2'),
       url('../fonts/347D2D_0_0.woff') format('woff'),
       url('../fonts/347D2D_0_0.ttf') format('truetype');
}
